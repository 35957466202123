<template>
  <section banner-image v-editable="blok" :data-spacing="blok?.spacing || 'default'">
    <div class="container">
      <div class="row">
        <a class="card" :href="getLinkFieldUrl(blok.link)">
          <figure class="card-image" :class="{ 'card-has-mobile': blok?.image_mobile?.filename }">
            <NuxtImg provider="storyblok" v-if="blok?.image?.filename" :src="blok.image.filename" :alt="`Card Image`" class="card-image-element" loading="lazy" :width="dimensions.width" :height="dimensions.height" />
            <NuxtImg provider="storyblok" v-if="blok?.image_mobile?.filename" :src="blok.image_mobile.filename" :alt="`Card Image Mobile`" class="card-image-element-mobile" loading="lazy" :width="dimensions_mobile.width" :height="dimensions_mobile.height" />
          </figure>
        </a>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/banner-image.scss" lang="scss"></style>
<script setup>
  const props = defineProps({ blok: Object });

  const dimensions = computed(() => {
    if (props.blok.image) {
      const urls = props.blok.image?.filename?.split('/')[5].split('x');
      return {
        width: urls[0],
        height: urls[1]
      };
    }
    return { width: 0, height: 0 };
  }); 
  
  const dimensions_mobile = computed(() => {
    if (props.blok.image_mobile) {
      const urls = props.blok.image_mobile?.filename?.split('/')[5].split('x');
      return {
        width: urls[0],
        height: urls[1]
      };
    }
    return { width: 0, height: 0 };
  });
</script>
